<template>
  <div :id="`player-container-${id}`" class="view-one-video">
    <div :id="`glplayer-${id}`" class="glplayer"></div>
  </div>
</template>

<script>
import Axios from 'axios'

export default {
  name: "MultipleVideoPlayer",
  props: {
    id: {
      type: String,
      default: "0",
    },
    options: {
      type: Object,
      default: null,
    },
    typeVideo: {
      type: String,
      default: null,
    },
    typeView: {
      type: Number,
      default: 1,
    },
    callBackReadyForDisplay: {
      type: Function,
    },
    isMute: {
      type: Boolean
    },
    isPlay: {
      type: Boolean
    },
  },
  data() {
    return {
      playerObj: null,
      config: {
        accurateSeek: true,
        token:
          "base64:QXV0aG9yOmNoYW5neWFubG9uZ3xudW1iZXJ3b2xmLEdpdGh1YjpodHRwczovL2dpdGh1Yi5jb20vbnVtYmVyd29sZixFbWFpbDpwb3JzY2hlZ3QyM0Bmb3htYWlsLmNvbSxRUTo1MzEzNjU4NzIsSG9tZVBhZ2U6aHR0cDovL3h2aWRlby52aWRlbyxEaXNjb3JkOm51bWJlcndvbGYjODY5NCx3ZWNoYXI6bnVtYmVyd29sZjExLEJlaWppbmcsV29ya0luOkJhaWR1",
        extInfo: {
          moovStartFlag: true,
          readyShow: true,
          autoCrop: false,
          core: 0,
          coreProbePart: 0.1,
          ignoreAudio: 1,
          volume: 1,
        },
        currentVideoLink: null,
      },
      currentSource: null,
      refTimeout: null,
    };
  },
  methods: {
    togglePlayPauseVideo(isPlay = null) {
      if (isPlay != null && this.playerObj) {
        if (isPlay) {
          this.playerObj.play();
        } else {
          this.playerObj.pause();
        }
      } else {
        if(this.playerObj) {
          if (this.playerObj.isPlaying()) {
            this.playerObj.pause();
          } else {
            this.playerObj.play();
          }
        }
      }
    },

    toggleSoundVideo(valueSound = null) {
      if (this.playerObj) {
        this.playerObj.setVoice(
          valueSound == null ? (this.playerObj.volume > 0 ? 0 : 1) : valueSound
        );
        let videoTag = document.getElementById(`glplayer-${this.id}-vjs_html5_api`)
        if(videoTag) {
          videoTag.volume = valueSound == null ? (this.playerObj.volume > 0 ? 0 : 1) : valueSound;
        }
      }
    },

    playVideo() {
      if(this.playerObj) {
        this.playerObj.play();
        let videoTag = document.getElementById(`glplayer-${this.id}-vjs_html5_api`)
        if(videoTag) {
          videoTag.play();
        }
      }
    },

    pauseVideo() {
      if(this.playerObj) {
        this.playerObj.pause();
        let videoTag = document.getElementById(`glplayer-${this.id}-vjs_html5_api`)
        if(videoTag) {
          videoTag.pause();
        }
      }
    },

    changeSourceVideo(source) {
      if(this.currentSource == source) {
        return;
      }

      this.currentSource = source;
      this.config = {
        ...this.config,
        player: `glplayer-${this.id}`,
        width: 100,
        height: 60,
      };
      if (this.playerObj) {
        this.playerObj.release();
      }
      this.initH265webjs(source);
    },

    async initH265webjs(videoURL) {
      if(videoURL != "https://google.com") {
        try {
          await Axios.get(videoURL);
        } catch(err) {
          return
        }
      }

      this.playerObj = window.new265webjs(videoURL, this.config);
      let mediaInfo = null;
      this.playerObj.onLoadFinish = () => {
        mediaInfo = this.playerObj.mediaInfo();
        
        this.toggleSoundVideo(this.isMute ? 0 : 1)

        if(this.callBackReadyForDisplay) {
          this.callBackReadyForDisplay(this.id);
        }
                this.toggleSoundVideo(this.isMute ? 0 : 1)
        if(this.refTimeout) {
          clearTimeout(this.refTimeout)
        }
        this.refTimeout = setTimeout(() => {
          if(this.isPlay) {
          this.playVideo();
        } else {
          this.pauseVideo;
        }
        }, 500);
        
      };

      this.playerObj.do();
      return this.playerObj;
    },

    handleVideo() {
      if (this.playerObj) {
        this.playerObj.release();
      }
      if (this.options.sources.length == 0 || !this.options.sources[0].src) {
        return;
      }
      var m3u8_url = this.options.sources[0].src;
      this.config = {
        ...this.config,
        player: `glplayer-${this.id}`,
        width: 1,
        height: 1,
      };
      if (this.typeVideo) {
        this.config = {
          ...this.config,
        };
      }
      this.initH265webjs(m3u8_url);
    },

    resizeVideo() {
      this.handleVideo();
    },

    createNewVideoPlayer() {
      this.handleVideo();
    },

    releaseVideo() {
        if(this.playerObj) {
            this.playerObj.release();
        }
    },

    handleLoadNewOption() {
      if (!this.options || this.options.length == 0) {
        if (this.playerObj) {
          this.playerObj.release();
        }
        return;
      }
      if (this.options.sources[0].src == this.currentVideoLink) {
        return;
      }
      this.handleVideo();
    },
  },
  mounted() {
    if (this.options) {
      this.createNewVideoPlayer();
    }
  },
  beforeDestroy() {
    if (this.playerObj) {
      this.playerObj.release();
    }
  },
  watch: {
    id: function (val) {
      if (this.playerObj && val) {
        this.playerObj.release();
        this.createNewVideoPlayer();
      }
    },
  },
};
</script>

<style scoped>
.view-one-video {
  width: 100% !important;
  height: 100% !important;
  background-color: #000;
}
</style>