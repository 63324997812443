<style lang="scss" scoped>
/* FADE IN */
.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter {
  opacity: 0;
}

/* GO TO NEXT SLIDE */
.slide-next-enter-active,
.slide-next-leave-active {
  transition: transform 0.5s ease-in-out;
}
.slide-next-enter {
  transform: translate(100%);
}
.slide-next-leave-to {
  transform: translate(-100%);
}

.slide-prev-enter-active,
.slide-prev-leave-active {
  transition: transform 0.5s ease-in-out;
}

.slide-prev-enter {
  transform: translate(-100%);
}

.slide-prev-leave-to {
  transform: translate(100%);
}

body {
  overflow: hidden;
  margin: 0;
  font-size: 50px;
  font-family: "Crimson Text", sans-serif;
  color: #fff;
}

#slider {
  width: 100%;
  position: relative;
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  z-index: 10;
  cursor: pointer;
  border: 3px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  top: calc(50% - 35px);
  left: 1%;
  transition: transform 0.3s ease-in-out;
  user-select: none;
}

.btn-next {
  left: auto;
  right: 1%;
}

.btn:hover {
  transform: scale(1.1);
}

.popup-relink {
    margin-left: 120px;
}
.notification-show-start {
    font-size: 20px;
    color: #058962;
}

.vjs-custom-skin {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.vjs-fluid {
    width: 100% !important;
    height: 100% !important;
}

.vjs-custom-skin {
    height: 100%;
}

.icon-btn-view {
    color: white;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.con-vs-tooltip {
    align-items: center;
    justify-content: center;
    display: flex;
}

.txt-current-page {
    color: white;
    font-size: 12px;
    min-width: 26px;
}

.name-camera {
    color: white;
    position: absolute;
    bottom: 4px;
    right: 8px;
    font-size: 12px;
}

.view-bound-tree {
    padding-inline: 24px;
}

@media only screen and (min-width: 1366px) {
    .scroll-area--nofications-dropdown, .scroll-area--cart-items-dropdowm {
        max-height: 7rem;
    }
}

@media only screen and (min-width: 1400px) {
    .scroll-area--nofications-dropdown, .scroll-area--cart-items-dropdowm {
        max-height: 26rem;
    }
}

@media only screen and (min-width: 1440px) {
    .scroll-area--nofications-dropdown, .scroll-area--cart-items-dropdowm {
        max-height: 16rem;
    }
}

@media only screen and (min-width: 1680px) {
    .scroll-area--nofications-dropdown, .scroll-area--cart-items-dropdowm {
        max-height: 26rem;
    }
}

@media only screen and (min-width: 1920px) {
    .scroll-area--nofications-dropdown, .scroll-area--cart-items-dropdowm {
        max-height: 27rem;
    }
}

@media only screen and (min-width: 2560px) {
    .scroll-area--nofications-dropdown, .scroll-area--cart-items-dropdowm {
        max-height: 44rem;
    }
}

@media only screen and (min-width: 3840px) {
    .scroll-area--nofications-dropdown, .scroll-area--cart-items-dropdowm {
        max-height: 60rem;
    }
}

.listVideoCamera {
    border-bottom: solid 1px #ccc
}

.footer-sidebar {
    padding: 10px;
}

//.add-sidebar {
//
//    ::v-deep .vs-sidebar--background {
//        z-index: 52010;
//    }
//
//    ::v-deep .vs-sidebar {
//        z-index: 52010;
//        width: 400px;
//        max-width: 90vw;
//    }
//}

.f-r {
    float: right;
}

.active {
    color: red;
}

.vue-daterange-picker .ranges {
    float: right;
    border-left: 1px solid #ddd;
}

.daterangepicker {
    border-left: none !important;
}

.title-page {
    font-size: 2.2rem;
    font-weight: bold;
    display: flex;
}

.text-font {
    opacity: 0.7;
}

.reportrange-text {
    border-radius: 5px;
    height: 38px;
    line-height: 27px;
}

.container-camera {
    border: 1px solid;
    flex-direction: row;
    overflow: hidden;
}

#content-live .container-camera-row {
    display: flex;
    flex-direction: row;
    flex: 1;
    transition: all 0.3s;
    width: 100%;
    height: 100%;
}

#content-live .container-column-camera {
    flex: 1;
    transition: all 0.3s;
    width: 100%;
    height: 100%;
}

.view-bound-camera {
    background-color: black;
    border: 2px solid white;
    flex: 1;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    display: flex;
    position: relative;
}

.view-video-control {
    background-color: rgba(var(--vs-primary),1);
    flex-direction: row;
    align-items: center;
    display: flex;
}

.view-control-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.container-page-camera {
    display: flex;
    background-color: black;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
}

.record-view {
    border: 1px solid white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 6px;
    border-radius: 10px;
    position: absolute;
    top: 6px;
    right: 8px;
}

.text-record {
    font-size: 8px;
    font-weight: 500;
    color: white;
    margin-left: 4px;
}

.circle-record {
    width: 6px;
    height: 6px;
    background-color: red;
    border-radius: 3px;
}

.loader {
  border: 6px solid #14bf8c;
  border-radius: 50%;
  border-top: 6px solid #fff;
  width: 24px;
  height: 24px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>

<style lang="scss">
#logoTetting {
    .vs-avatar--con-img {
        width: 100% !important;
        height: 100% !important;
        border-radius: 50% !important;
        padding-top: 0px !important;
    }
}

.vjs-fluid {
    width: 100% !important;
    height: 100% !important;
}

.video-js.vjs-fluid, .video-js.vjs-16-9, .video-js.vjs-4-3, .video-js.vjs-9-16, .video-js.vjs-1-1 {
    height: 100% !important;
    display: contents;
}

.tree-event .tree-box {
    margin-top:3px;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
}

.rmNode {
    background-color: rgba(var(--vs-danger), 0.15) !important;
    color: rgba(var(--vs-danger), 1) !important;
    line-height: 13px !important;
}

#content-live .tree-container {
    width: 100%;
    height: 0px;
    border-width: 0px;
}

.tree-wholerow {
    background: #e1e1e1
}

.view-choose-camera {
    position: relative;
    display: flex;
}

.view-top-btn {
    height: 40px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    background-color: white;
    z-index: 10;
    border-radius: 6px;
}

#content-live .halo-tree .node-title {
    cursor: default;
}

#content-live .halo-tree .node-title:hover {
    background-color: white;
}

.tree-box ul.halo-tree:first-of-class {
  margin-top: 66px;
}

.txt-choose {
    font-size: 18px;
    font-weight: 500;
    color: black;
}

#content-live .container-camera, .view-video-control{
    margin: 0px !important;
}

#content-live .tree-box {
    background: #fff;
    position: absolute;
    z-index: 50000;
    max-height: 500px;
    overflow: auto;
    margin-top: 0px;
    min-width: 350px;
    bottom: 0px;
    right: -100px;
}

#content-live .halo-tree .inputCheck {
    transform: scale(1.3);
    margin-left: 10px;
    margin-right: 4px;
}

</style>

<style scoped>
#content-live .halo-tree {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#content-live .halo-tree li {
    padding-right: 0px !important;
}

#content-live .halo-tree .halo-tree li {
    padding-left: 8px;
}

#content-live .halo-tree li:before {
    left: -10px;
}

#content-live .halo-tree li:after {
    left: -10px;
    width: 14px !important;
}

#content-live .halo-tree .tree-node-el {
    display: flex;
    align-items: center;
    background-color: transparent;
}

#content-live #tree-camera .vs-con-dropdown {
    right: 4px;
}

#content-live .halo-tree .node-title {
    text-align: left;
    padding: 3px 0px;
    display: flex;
    flex: 1;
}

#content-live .halo-tree .span-row-tree {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-radius: 4px;
}

#content-live #tree-camera .vs-con-dropdown {
    position: relative !important;
    display: flex !important;
    left: auto !important;
    top: auto !important;
    bottom: auto !important;
}

#content-live #tree-camera .vs-con-dropdown span.feather-icon.select-none {
    bottom: auto !important;
}

.scroll-area--nofications-dropdown {
    padding-right: 10px;
}

#tree-camera button span.feather-icon.select-none {
    left: 0px;
}

#content-live #tree-camera .feather-icon{
    padding-top: 4px;
}

#content-live .halo-tree .con-vs-tooltip {
    /* padding-bottom: 7px; */
    padding-left: 6px;
}

#content-live .halo-tree .node-selected {
    border: 1px solid #ddd;
    color: #fff !important;
    background-color: #19b179 !important;
    transition: all 0.1s;
}

#content-live .halo-tree .span-row-tree:hover  {
    border: 1px solid #ddd;
    color: #fff !important;
    background-color: #19b179 !important;
}

#content-live .halo-tree .span-row-tree:hover .node-title, .halo-tree .span-row-tree:hover b {
    background-color: #19b179;
    color: white;
    transition: all 0.1s;
    border-color: transparent;
}

#content-live #tree-select-livestream .align-feather-share{
    vertical-align: middle;
    width: 16px;
    height: 16px
}

#content-live  #tree-select-livestream .align-feather {
    vertical-align: middle;
    width: 1rem;
}

</style>

<template>
    <div id="content-live">
        <vx-card style="min-height: 500px" >
            <fullscreen :fullscreen.sync="isFullScreen">
                <div class="vx-row">
                    <div class="vx-col md:w-full flex items-center mb-5">
                        <TitleScreen></TitleScreen>
                    </div>
                </div>
            <!-- Start View video view -->
            <div class="vx-row container-camera" ref="refViewVideo" :style="{height: heightVideoBound + 'px'}">
                <div id="slider">

                    <transition-group tag="div" :name="transitionName" class="slides-group">
                        <div v-if="show" :key="currentPage" class="slide">

                        <!-- Start view item page -->
                        <div class="container-page-camera">
                            <div :style="{height: currentTypeColumn == 1 ? '100%' : currentTypeColumn == 4 ? '50%' : '33.33%' }" class="container-camera-row" v-for="(itemRow, indRow) in listVideoHandle[currentPage]" :key="indRow">
                                <div class="view-bound-camera"
                                    @click="(currentTypeColumn == 1 || itemCam.isEmpty) ? null : onSelectCamera(indRow, indCam, currentPage)"
                                    :style="{borderColor:(currentTypeColumn != 1 && indCam == currentCamInd &&  indRow == currentRowInd && pageCamInd == currentPage ) ? '#f02' : '#fff' }" v-for="(itemCam, indCam) in itemRow" :key="indCam">
                                    <div class="bound-one-camera" v-if="!itemCam.isEmpty">
                                        <MultipleVideoPlayer
                                            :typeView="currentTypeColumn"
                                            :typeVideo="`hls`"
                                            :callBackReadyForDisplay="callBackReadyForDisplay"
                                            :ref="'videoPlayer-' + currentPage + '-' + indRow + '-' + indCam "
                                            :key="`${currentPage}-${indRow}-${indCam}`"
                                            :options="playerOptions[currentPage + '-' + indRow + '-' + indCam]"
                                            :isMute="isMute"
                                            :isPlay="isPlay"
                                            :id="`${currentPage}-${indRow}-${indCam}`" />
                                        <!-- <span style="color: white" >{{currentPage}} - {{indRow}} - {{indCam}} - {{currentPage * currentTypeColumn + indRow * (currentTypeColumn == 4 ? 2 : currentTypeColumn == 9 ? 3 : 1) + indCam}}</span> -->
                                        <span class="name-camera">{{itemCam.cameraName}}</span>

                                        <div class="record-view" :key="isLoadingRecord" v-show="listCheckCameraIsRecording[`${itemCam.id}`]" >
                                            <div class="circle-record" />
                                            <span class="text-record">Record</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End view item page -->

                        </div>
                    </transition-group>
                </div>

            </div>
            <!-- End View video view -->

            <div class="vx-row view-video-control p-3">
                <div class="view-control-item" style="flex: 1">
                    <vx-tooltip class="mr-2 md:mr-3 lg:mr-3 xl:mr-3 sm:mr-3" :text="isPlay ? $t('liveStream.pauseVideo') : $t('liveStream.playVideo')" position="top">
                        <vs-icon class="icon-btn-view" size="medium" :icon="isPlay ? 'pause' :'play_arrow'" @click="onClickPlay"></vs-icon>
                    </vx-tooltip>
                    <vx-tooltip class="mr-2 md:mr-3 lg:mr-3 xl:mr-3 sm:mr-3" :text="isMute ? $t('liveStream.unmuteVideo') : $t('liveStream.muteVideo')" position="top">
                        <vs-icon class="icon-btn-view" size="medium" :icon="isMute ? 'volume_off' : 'volume_up'" @click="isMute = !isMute"></vs-icon>
                    </vx-tooltip>
                    <vx-tooltip class="mr-2 md:mr-3 lg:mr-3 xl:mr-3 sm:mr-3" :text="currentCameraIsSelected != undefined && listCheckCameraIsRecording[`${currentCameraIsSelected.id}`] ? $t('menuEvent.stopRecoding') : $t('menuEvent.startRecording')" position="top" v-show="currentTypeColumn == 1 && listVideoHandle.length > 0 && currentCameraIsSelected.evgCamera && currentCameraIsSelected.evgCamera.streamingStatus == 1" >
                        <div v-if="isRecord" class="loader" />

                        <svg v-if="!isRecord" xmlns="http://www.w3.org/2000/svg" width="28" height="28" class="icon-btn-view"
                                @click="handleRecord()" :fill="'#fff'"
                                viewBox="0 0 25 25">
                            <path v-if="currentCameraIsSelected != undefined && listCheckCameraIsRecording[`${currentCameraIsSelected.id}`]"
                                d="M21 6.5l-4 4V7c0-.55-.45-1-1-1H9.82L21 17.18V6.5zM3.27 2L2 3.27 4.73 6H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.21 0 .39-.08.54-.18L19.73 21 21 19.73 3.27 2z"
                                fill="var(--iv-theme--control--primary-color--idle)"></path>

                            <path v-if="currentCameraIsSelected == undefined || !listCheckCameraIsRecording[`${currentCameraIsSelected.id}`]"
                                d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"
                                fill="var(--iv-theme--control--primary-color--idle)"></path>
                        </svg>
                    </vx-tooltip>
                    <vx-tooltip :text="$t('liveStream.capture')" position="top" v-show="currentTypeColumn == 1 && listVideoHandle.length > 0 && currentCameraIsSelected.evgCamera && currentCameraIsSelected.evgCamera.streamingStatus == 1">
                        <!-- <vs-icon class="icon-btn-view" size="medium" icon="stop" @click="currentTypeColumn = 1"></vs-icon> -->
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" v-show="!isCapture" class="icon-btn-view"
                                @click="handleCapture()" :fill="'#fff'"
                                viewBox="0 0 25 25">
                            <circle xmlns="http://www.w3.org/2000/svg" cx="12" cy="12" r="3.2"/>
                            <path
                                d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"
                                fill="var(--iv-theme--control--primary-color--idle)"></path>
                        </svg>

                        <div v-if="isCapture" class="loader" />
                    </vx-tooltip>
                </div>

                <div class="view-control-item" style="flex: 1; justify-content: end;">

                    <vx-tooltip class="mr-4 md:mr-10 lg:mr-10 xl:mr-10 sm:mr-10" :text="$t('liveStream.chooseCam')" position="top">
                        <div class="view-choose-camera">
                            <MySelectTree
                                    ref='treeCamera'
                                    :data='treeData'
                                    :searchtext="searchtext"
                                    v-model='treeCameraValue'
                                    :multiple="true"
                                    id="tree-select-livestream"
                                    :tpl='tpl'
                                    :halfcheck='false'
                                    :node-check="0"/>
                            <vs-icon class="icon-btn-view"  size="medium" icon="list" @click="isShowPopupChooseCam = !isShowPopupChooseCam"></vs-icon>
                        </div>
                    </vx-tooltip>

                    <vx-tooltip class="mr-2 md:mr-2 lg:mr-2 xl:mr-2 sm:mr-2" text="1 camera" position="top">
                        <!-- <vs-icon class="icon-btn-view" size="medium" icon="stop" @click="currentTypeColumn = 1"></vs-icon> -->
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" class="icon-btn-view"
                                @click="currentTypeColumn = 1" :fill="currentTypeColumn == 1 ? '#fff' : '#c7c7c7'"
                                viewBox="0 0 25 25">
                            <path
                                d="M0 0h24v24H0z"
                                fill="var(--iv-theme--control--primary-color--idle)"></path>
                        </svg>
                    </vx-tooltip>
                    <vx-tooltip class="mr-2 md:mr-2 lg:mr-2 xl:mr-2 sm:mr-2" text="4 camera" position="top">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="icon-btn-view"
                                @click="currentTypeColumn = 4" :fill="currentTypeColumn == 4 ? '#fff' : '#c7c7c7'"
                                viewBox="0 0 25 25">
                            <path
                                d="M18.663 12.084h-4.24a2.31 2.31 0 0 0-2.34 2.338v4.24A2.337 2.337 0 0 0 14.423 21h4.24A2.304 2.304 0 0 0 21 18.662v-4.24a2.306 2.306 0 0 0-2.337-2.338M7.585 1H3.338A2.314 2.314 0 0 0 1 3.337v4.248c0 1.31 1.03 2.33 2.338 2.33h4.247c1.308 0 2.33-1.02 2.33-2.33V3.337A2.307 2.307 0 0 0 7.586 1m-.001 11.084H3.338A2.313 2.313 0 0 0 1 14.425v4.24A2.31 2.31 0 0 0 3.338 21h4.247c1.308 0 2.33-1.02 2.33-2.338v-4.24a2.309 2.309 0 0 0-2.33-2.338M18.663 1h-4.24a2.313 2.313 0 0 0-2.34 2.337v4.248a2.334 2.334 0 0 0 2.34 2.33h4.24c1.316 0 2.337-1.02 2.337-2.33V3.337C21 2.027 19.98 1 18.663 1"
                                fill="var(--iv-theme--control--primary-color--idle)"></path>
                        </svg>
                    </vx-tooltip>
                    <vx-tooltip class="mr-4 md:mr-10 lg:mr-10 xl:mr-10 sm:mr-10" text="9 camera" position="top">
                        <!-- <vs-icon class="icon-btn-view" size="medium" icon="apps" @click="currentTypeColumn = 9"></vs-icon> -->
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="icon-btn-view"
                                @click="currentTypeColumn = 9" :fill="currentTypeColumn == 9 ? '#fff' : '#c7c7c7'"
                                viewBox="0 0 25 25">
                            <path
                                d="M5.237 1H2.504A1.49 1.49 0 0 0 1 2.504v2.733c0 .842.662 1.5 1.504 1.5h2.733c.842 0 1.5-.658 1.5-1.5V2.504C6.737 1.662 6.08 1 5.237 1m0 7.132H2.504A1.49 1.49 0 0 0 1 9.635v2.73c0 .845.662 1.503 1.504 1.503h2.733c.842 0 1.5-.658 1.5-1.503v-2.73c0-.84-.658-1.503-1.5-1.503m0 7.132H2.504c-.842 0-1.504.66-1.504 1.504v2.73C1 20.34 1.662 21 2.504 21h2.733c.842 0 1.5-.658 1.5-1.504v-2.73c0-.84-.658-1.502-1.5-1.502M12.368 1H9.636a1.49 1.49 0 0 0-1.504 1.504v2.733c0 .842.662 1.5 1.504 1.5h2.732c.843 0 1.5-.658 1.5-1.5V2.504c0-.842-.657-1.504-1.5-1.504m0 7.132H9.636a1.49 1.49 0 0 0-1.504 1.503v2.73c0 .845.662 1.503 1.504 1.503h2.732c.843 0 1.5-.658 1.5-1.503v-2.73c0-.84-.657-1.503-1.5-1.503m0 7.132H9.636c-.842 0-1.504.66-1.504 1.504v2.73c0 .844.662 1.502 1.504 1.502h2.732c.843 0 1.5-.658 1.5-1.504v-2.73c0-.84-.657-1.502-1.5-1.502M19.5 1h-2.732a1.49 1.49 0 0 0-1.504 1.504v2.733c0 .842.662 1.5 1.504 1.5H19.5c.843 0 1.5-.658 1.5-1.5V2.504C21 1.662 20.343 1 19.5 1m0 7.132h-2.732a1.49 1.49 0 0 0-1.504 1.503v2.73c0 .845.662 1.503 1.504 1.503H19.5c.843 0 1.5-.658 1.5-1.503v-2.73c0-.84-.657-1.503-1.5-1.503m0 7.132h-2.732c-.842 0-1.504.66-1.504 1.504v2.73c0 .845.662 1.503 1.504 1.503H19.5c.843 0 1.5-.657 1.5-1.503v-2.73c0-.842-.657-1.503-1.5-1.503"
                                fill="var(--iv-theme--control--primary-color--idle)"></path>
                        </svg>
                    </vx-tooltip>

                    <vx-tooltip :text="$t('liveStream.preCam')" position="top">
                        <vs-icon class="icon-btn-view" size="medium" icon="chevron_left" @click="slide(-1)"></vs-icon>
                    </vx-tooltip>
                    <span class="txt-current-page">{{listVideoHandle.length == 0 ? 0 : (currentPage == undefined ? 0 : currentPage + 1)}} / {{listVideoHandle.length}}</span>
                    <vx-tooltip :text="$t('liveStream.nextCam')" position="top">
                        <vs-icon class="icon-btn-view" size="medium" icon="chevron_right" @click="slide(1)"></vs-icon>
                    </vx-tooltip>

                    <vx-tooltip class="ml-4 md:ml-10 lg:ml-10 xl:ml-10 sm:ml-10" :text="isFullScreen ? $t('liveStream.closeFullScreen') : $t('liveStream.fullScreen')" position="top">
                        <vs-icon class="icon-btn-view" size="medium" :icon="isFullScreen ? 'fullscreen_exit' : 'fullscreen'" @click="toggleFullScreen()"></vs-icon>
                    </vx-tooltip>
                </div>
            </div>
            </fullscreen>
        </vx-card>
        <vs-popup title="Thông báo" :active.sync="branchEmpty">
            <span class="flex justify-center text-center">{{ $t('textCamera.branchEmpty') }}</span>
            <div class="flex justify-center">
                <vs-button color="primary" icon-pack="feather" icon="icon-plus" class="btn-add mt-4"
                    @click="clickAddCBranch">
                    <span class="text_add-camera">{{ $t('button.addCamera') }}</span>
                </vs-button>
            </div>
        </vs-popup>
    </div>

</template>
<script>
import vSelect from 'vue-select';
import {videoPlayer} from 'vue-videojs7'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueFullscreen from 'vue-fullscreen'
import Vue from 'vue'
import MySelectTree from './../../../components/my-select-tree/MySelectTree.vue'

import MultipleVideoPlayer from './../../../components/video-player-h265/MultipleVideoPlayer.vue'

import imgErr from "../../../assets/images/pages/cam-error.png";
import imgWaiting from "../../../assets/images/pages/cam-waiting.png";
import imgSuccess from "../../../assets/images/pages/cam-succes.png";
import TitleScreen from "../../../layouts/main/TitleScreen";
import AddCamera from "../camera/my-cameras/AddCamera";

Vue.use(VueFullscreen)

const TYPE_VIDEO_1 = 1;
const TYPE_VIDEO_4 = 4;
const TYPE_VIDEO_9 = 9;

export default {
    name: 'stream-camera',
    components: {
        VuePerfectScrollbar,
        vSelect,
        videoPlayer,
        MySelectTree,
        MultipleVideoPlayer,
        TitleScreen,
        AddCamera
    },
    computed: {
        searchtext() {
            return this.$t('menuEvent.searchCam');
        },
    },
    data() {
        return {
            heightVideo: 0,
            currentTypeColumn: TYPE_VIDEO_1,
            listVideoHandle: [], // handle list video to render view
            currentPage: 0,
            currentRowInd: 0,
            currentCamInd: 0,
            pageCamInd: 0,
            isMute: true,
            isPlay: true,
            isFullScreen: false,
            widthParentContainer: 0,
            heightParentContainer: 0,
            direction: 1,
            transitionName: "fade",
            show: false,
            heightVideoBound: 500,
            isHandleResetWork: false,
            isShowPopupChooseCam: false,
            listCheckCameraIsRecording: {},
            isCapture: false,
            isRecord: false,
            currentCameraIsSelected: null,
            isFirstOpenPage: true,

            treeCameraValue: [],
            cameraSelectedValue: [],
            treeData: [{
                isOrganization: true,
                title: null,
                expanded: true,
                children: []
            }],
            selectedCameraIds: [],
            cameras: [],
            imgErr: null,
            imgWaiting: null,
            imgSuccess: null,
            imgEmpty: null,
            listVideoCurrent: [],

            onSrcSteamCamera: true,
            openPopupErrCamera: false,
            seen: true,
            styleObject: {},
            actives: false,
            offsetWidthCamera: 0,
            maxPageNumbers: 7,
            totalPages: 1,
            totalCamera: 1,
            perPage: 1,
            isOneCamera: true,
            organizationBranchOptions: [],
            cameraGroupOptions: [],
            streamInfo: {},
            branchId: null,
            playerOptions: {
            },
            maxHightGridVideo: '',
            listVideo: [],
            isActive: false,
            indexShowVideos: null,
            streamRecordingId: null,
            isLoadingRecord: 0,
            branchEmpty: false,
        }
    },
    async created() {
        this.imgSuccess = imgSuccess;
        this.imgErr = imgErr;
        this.imgWaiting = imgWaiting;
        this.resizeVideo();
        await this.getCamera();
    },
    methods: {
        onClickPlay() {
            this.isPlay = !this.isPlay;
        },

        callBackReadyForDisplay(id) {
            this.handlePlayOrPauseVideo();
            if(this.$refs[`videoPlayer-${id}`]) {
                let pa = id.split('-')
                if(pa.length >= 3 && this.$refs[`videoPlayer-${id}`].length > 0) {
                    this.$refs[`videoPlayer-${id}`][0].toggleSoundVideo(this.isMute ? 0 : (
                        (pa[0] == this.pageCamInd && pa[1] == this.currentRowInd && pa[2] == this.currentCamInd) ? 1 : 0
                    ))
                }
            }
        },

        toggleFullScreen() {
            this.isFullScreen = !this.isFullScreen;
        },

        handleChangePage() {
            this.handlePlayOrPauseVideo();
            this.handleMuted();
        },

        handlePlayOrPauseVideo() {
            for (const [key, value] of Object.entries(this.$refs)) {
                if(key.includes('videoPlayer') && value[0]){
                    value[0].togglePlayPauseVideo(this.isPlay);
                }
            }
        },

        handleMuted() {
            for (const [key, value] of Object.entries(this.$refs)) {
                if(key.includes('videoPlayer') && value[0]){
                    let player = value[0]
                    let valueNameRef = key.split('-')
                    if(this.currentTypeColumn == TYPE_VIDEO_1){
                        value[0].toggleSoundVideo(this.isMute ? 0 : 1)
                    } else {
                        if(valueNameRef.length == 4 && valueNameRef[1] == this.pageCamInd
                            && valueNameRef[2] == this.currentRowInd
                            && valueNameRef[3] == this.currentCamInd){
                            if(this.isMute){
                                player.toggleSoundVideo(0)
                            }else {
                                player.toggleSoundVideo(1)
                            }
                        }else {
                            player.toggleSoundVideo(0)
                        }
                    }
                }
            }
        },

        onSelectCamera(indRow, indCam, currentPage){
            if(indRow == this.currentRowInd && indCam == this.currentCamInd && this.pageCamInd == currentPage){
                this.currentTypeColumn = TYPE_VIDEO_1;
                return
            }
            this.currentRowInd = indRow;
            this.currentCamInd = indCam;
            this.pageCamInd = currentPage;
        },

        slide(dir) {
            this.direction = dir;
            dir === 1
                ? (this.transitionName = "slide-next")
                : (this.transitionName = "slide-prev");
            var len = this.listVideoHandle.length;
            this.currentPage = (this.currentPage + dir % len + len) % len;
        },

        closePopup(){
            this.resizeVideo();
            this.openPopupErrCamera = false;
            this.onSrcSteamCamera = true;
        },

        changeStatusRecording() {
            if (this.isRecord) {
                this.stopRecoding(this.streamRecordingId);
            } else {
                this.startRecoding(this.streamRecordingId);
            }
        },

        handleCapture() {
            if(!this.currentCameraIsSelected){
                return;
            }
            this.isCapture = true;
            this.$crm.post(`/camera/extract-image`, {
                "cameraId": this.currentCameraIsSelected.id,
                "size": "600X480",
            }).then(res => {
                this.isCapture = false;
                if(res.data.data) {
                    var a = document.createElement("a");
                    a.href = "data:image/png;base64," + res.data.data;
                    a.download = "Image.png";
                    a.click();
                    // window.location.href = 'data:application/octet-stream;base64,' + res.data.data;
                }
            }).catch(err => {
                this.isCapture = false;
                this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
        },

        handleCheckIsRecordCamera() {
            let listIdExist = []
            for (const [key, value] of Object.entries(this.listCheckCameraIsRecording)) {
                listIdExist.push(key)
            }

            this.listVideoCurrent.forEach(element => {
                if(!listIdExist.includes(`${element.id}`)){
                    this.isRecording(`${element.id}`)
                }
            });
        },

        isRecording(valueId) {
            this.$crm.post('/camera/is-recording/' + valueId).then((response) => {
                this.listCheckCameraIsRecording[valueId] = response.data;
                this.isLoadingRecord++;
            }).catch((err) => {
                // this.$vs.notify({
                //     title: 'Error',
                //     text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                //     iconPack: 'feather',
                //     icon: 'icon-alert-circle',
                //     color: 'danger'
                // });
            });
        },

        handleRecord() {
            if(!this.currentCameraIsSelected){
                return;
            }
            if(this.listCheckCameraIsRecording[`${this.currentCameraIsSelected.id}`]){
                this.stopRecoding();
            } else {
                this.startRecoding();
            }
        },

        startRecoding() {
            let this_ = this;
            if(!this_.currentCameraIsSelected){
                return
            }
            this_.isRecord = true;
            this_.$crm.post('/camera/start-recording/' + this_.currentCameraIsSelected.id).then(() => {
                this_.isRecord = true;
                this_.styleObject = {color: 'red'};
                this_.$vs.notify({
                    text: this.$t('textCamera.textStartRecord'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                });
                this.listCheckCameraIsRecording[`${this.currentCameraIsSelected.id}`] = true;
                this_.isRecord = false;
            }).catch((err) => {
                this_.isRecord = false;
                return this.$vs.notify({
                    title: this.$t('textCamera.err'),
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },

        stopRecoding() {
            let this_ = this;
            if(!this_.currentCameraIsSelected){
                return
            }
            this_.isRecord = true;
            this_.$crm.post('/camera/stop-recording/' + this_.currentCameraIsSelected.id).then(() => {
                this_.isRecord = false;
                this_.styleObject = {};
                this_.$vs.notify({
                    text: this.$t('textCamera.textStopRecord'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                });
                this.listCheckCameraIsRecording[`${this.currentCameraIsSelected.id}`] = false;
            }).catch((err) => {
                this_.isRecord = false;
                return this.$vs.notify({
                    title: this.$t('textCamera.err'),
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },

        resizeVideo() {
            var hScreen = document.documentElement.clientHeight;
            if(this.isFullScreen) {
                this.heightVideoBound = hScreen - 46;
            }else {
                this.heightVideoBound = hScreen - 230;
            }
        },

        clickAddCBranch() {
            this.branchEmpty = false
            setTimeout(() => {
                this.$router.push('/user/add-organization-branch')
            }, 500);
        },

        getCamera() {
            return new Promise(resolve => {
                this.$vs.loading();
                this.cameras = [];
                this.camerasBinding = [];
                this.treeData = [{
                    isOrganization: true,
                    title: null,
                    expanded: true,
                    children: [],
                    checked: true
                }]
                this.userInfo = JSON.parse(localStorage.getItem('userInfo') || {});
                this.treeData[0].title = this.userInfo.username;
                this.$crm.get(`/camera-group/find-camera-by-org?type=0`).then((response) => {
                    this.$vs.loading.close()
                    if (response.data.length == 0) {
                        this.branchEmpty = true
                    }
                    if (response.data && response.data.length > 0) {
                        let camSelectFirst = [];
                        response.data.forEach((data) => {
                            let childOrganization = {
                                title: data.organizationBranchName,
                                expanded: true,
                                value: data,
                                children: [],
                                checked: true
                            }
                            let childOrganizationBranchInGroup = {};

                            if (data.cameraGroupResponseDTO && data.cameraGroupResponseDTO.length > 0) {
                                data.cameraGroupResponseDTO.forEach(camInGroup => {
                                    let childGroup = camInGroup.cameras.map(cam => {
                                        if (!cam.evgCamera) return;
                                        let v =  {
                                            title: cam.cameraName,
                                            value: cam,
                                            isCamera: true,
                                            checked: true,
                                        }
                                        camSelectFirst.push(cam)
                                        return v;
                                    })
                                    childOrganizationBranchInGroup = {
                                        title: camInGroup.cameraGroupName,
                                        expanded: true,
                                        value: camInGroup,
                                        children: childGroup,
                                        checked: false
                                    }
                                    childOrganization.children.push(childOrganizationBranchInGroup);
                                });
                            }
                            if (data.camerasNotInGroup && data.camerasNotInGroup.length > 0) {
                                let childOrganizationBranchNotInGroup = data.camerasNotInGroup.map(camNotInGroup => {
                                    let v = {
                                        title: camNotInGroup.cameraName,
                                        value: camNotInGroup,
                                        isCamera: true,
                                        checked: true
                                    }
                                    camSelectFirst.push(camNotInGroup)
                                    return v;
                                })
                                childOrganization.children.push(...childOrganizationBranchNotInGroup);
                            }
                            if(data.cameraGroupShareResponseDTO && data.cameraGroupShareResponseDTO.length > 0){
                                data.cameraGroupShareResponseDTO.forEach(camShare => {
                                    let childGroup = camShare.cameras.map(cam => {
                                        if (!cam.evgCamera) return;
                                        cam.isCameraShare = true;
                                        let v = {
                                            title: cam.cameraName,
                                            value: cam,
                                            isCamera: true,
                                            checked: true,
                                        }
                                        camSelectFirst.push(cam)
                                        return v;
                                    })
                                    childOrganizationBranchInGroup = {
                                        title: camShare.cameraGroupName,
                                        expanded: true,
                                        value: camShare,
                                        isGroupCamera: true,
                                        isGroupShare: true,
                                        children: childGroup
                                    }
                                    childOrganization.children.push(childOrganizationBranchInGroup);
                                });
                            }

                            this.treeData[0].children.push(childOrganization);
                        });

                        this.treeCameraValue.push(camSelectFirst.cameraName)
                        this.listVideoCurrent = [...camSelectFirst];
                    }
                    this.$vs.loading.close();
                    resolve(true);
                }).catch((err) => {
                    resolve(true);
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
        },

        tpl(...args) {
            let {0: nodeValue, 2: parent, 3: index} = args
            let titleClass = nodeValue.selected ? 'node-title node-selected' : 'node-title'
            if (nodeValue.searched) titleClass += ' node-searched'
            let img = null;
            if (nodeValue.isCamera) {
                let streamingStatus = nodeValue.value.evgCamera.streamingStatus;
                switch (streamingStatus) {
                    case 0:
                        img = <img style="width:20px; height:15px; margin-left: 5px" src={this.imgErr}/>;
                        break;
                    case 1:
                        img = <img style="width:20px; height:15px; margin-left: 5px" src={this.imgSuccess}/>;
                        break;
                    case 8:
                        img = <img style="width:20px; height:15px; margin-left: 5px" src={this.imgWaiting}/>;
                        break;
                }
            }
            return <span style="display: inline-flex; align-items: center;">
                {img}
                <span class={titleClass} domPropsInnerHTML={nodeValue.title} onClick={() => {
                    this.nodeSelected(nodeValue)
                }}></span>
            </span>
        },

        onHandleClickSelectCamera() {
            let cameras = this.$refs.treeCamera.getTreeRef().getCheckedNodes().filter(x => x.isCamera);
            this.listVideoCurrent = cameras.map(t => t.value);
            this.isShowPopupChooseCam = false;
        },

        handleSortVideo() {
            let listTmp = []
            let listAfter = [];
            let dataEmpty = {
                isEmpty: true
            };
            let tmpListVideo = [...this.listVideoCurrent]
            if(tmpListVideo.length == 0){
                this.listVideoHandle = [];
                return;
            }
            switch (this.currentTypeColumn) {
                case TYPE_VIDEO_1: {
                    listTmp = tmpListVideo.map(e => [[e]])
                    break
                }
                case TYPE_VIDEO_4: {
                    listTmp = this.groupArray(tmpListVideo, 4)
                    break
                }
                case TYPE_VIDEO_9: {
                    listTmp = this.groupArray(tmpListVideo, 9)
                    break
                }
            }
            if (listTmp.length > 0 && this.currentTypeColumn != TYPE_VIDEO_1) {
                let listLast = listTmp[listTmp.length - 1]
                let v = this.currentTypeColumn == TYPE_VIDEO_4 ? 4 : 9
                if (listLast.length < v) {
                    let sub = v - listLast.length
                    if (sub > 0) {
                        Array.from(Array(sub).keys()).forEach(_ => {
                            listLast.push(dataEmpty)
                        });
                    }
                }
            }
            if (this.currentTypeColumn == TYPE_VIDEO_4) {
                listTmp.forEach(e => {
                    listAfter.push([[e[0], e[1]], [e[2], e[3]]]);
                })
                listTmp = listAfter;
            }
            if (this.currentTypeColumn == TYPE_VIDEO_9) {
                listTmp.forEach(e => {
                    listAfter.push([[e[0], e[1], e[2]], [e[3], e[4], e[5]], [e[6], e[7], e[8]]]);
                })
                listTmp = listAfter;
            }
            listTmp.forEach((page, indPage) => {
                page.forEach((row, indRow) => {
                    row.forEach((item, indCam) => {
                        if(!item.isEmpty) {
                            this.playerOptions[indPage + '-' + indRow + '-' + indCam] = {
                                autoplay: false,
                                playbackRates: [1.0],
                                muted: false,
                                preload: "auto",
                                fluid: true,
                                controls: false,
                                sources: [
                                    {
                                        withCredentials: false,
                                        type: 'application/x-mpegurl',
                                        src: item.evgCamera.hlsPlayLink,
                                        // src: 'https://cdn.intface.vn/live/19105a21458618e46af8559abb6966d28fa.stream/playlist.m3u8',
                                        // src: require('./../../../assets/chuchuwen-hk_moov.mp4'),
                                        // src: 'http://localhost:8080/media/demooo.d9061d3d.mp4'
                                    }
                                ],
                                notSupportedMessage: this.$t('liveStream.errPlayVideo'),
                            };
                        }
                    })
                })
            })
            this.listVideoHandle = listTmp;
            if(this.currentPage >= listTmp.length) {
                this.currentPage = 0;
            }
        },

        groupArray(data, n) {
            var group = [];
            for (var i = 0, j = 0; i < data.length; i++) {
                if (i >= n && i % n === 0)
                    j++;
                group[j] = group[j] || [];
                group[j].push(data[i])
            }
            return group;
        },

        getCurrentCameraSelected() {
            if(this.currentTypeColumn == TYPE_VIDEO_1) {
                try{
                    let cam = this.listVideoHandle[this.pageCamInd][this.currentRowInd][this.currentCamInd];
                    this.currentCameraIsSelected = cam;
                }catch(err){

                }
            }
        },

        releaseAllVideo() {
            for (const [key, value] of Object.entries(this.$refs)) {
                if(key.includes('videoPlayer') && value[0]){
                    try{
                        value[0].releaseVideo();
                    }catch(err) {

                    }
                }
            }
        }
    },
    mounted() {
        // Register an event listener when the Vue component is ready
        window.addEventListener('resize', this.resizeVideo)
        this.show = true;
        document.getElementsByClassName("tag-box-container")[0].remove();
        let childAdd = document.createElement('div')
        childAdd.classList.add('view-top-btn')
        let btn = document.createElement('button')
        let txt = document.createElement('span')
        txt.innerText = this.$t('liveStream.chooseCam')
        txt.classList.add("txt-choose")
        btn.classList.add("vs-component")
        btn.classList.add("vs-button")
        btn.classList.add("vs-button-primary")
        btn.classList.add("vs-button-filled")
        btn.innerText = this.$t('statusActive.agree')
        childAdd.appendChild(txt)
        childAdd.appendChild(btn)
        document.getElementById("header-tree").prepend(childAdd)
        btn.addEventListener("click", this.onHandleClickSelectCamera)

        let heightGroup = window.screen.height;
        heightGroup -= 390;
        if(document.getElementsByClassName("container-camera").length > 0) {
            document.getElementsByClassName("container-camera")[0].style.height = heightGroup + 'px';
        }
    },
    beforeDestroy() {
        // Unregister the event listener before destroying this Vue instance
        window.removeEventListener('resize', this.resizeVideo)
        this.releaseAllVideo();
    },
    watch: {
        currentTypeColumn: function(val, oldVal) {
            if(val == oldVal){
                return
            }
            this.handleSortVideo();
            let pageOne = Math.floor( this.pageCamInd * oldVal + this.currentRowInd * (oldVal == TYPE_VIDEO_9 ? 3 : oldVal == TYPE_VIDEO_4 ? 2 : 1) + this.currentCamInd);
            let pM = Math.floor(pageOne/val);
            let vD = pageOne % val;
            let rOne = Math.floor(vD / (val == TYPE_VIDEO_4 ? 2 : val == TYPE_VIDEO_9 ? 3 : 1));
            let cOne = vD % (val == TYPE_VIDEO_4 ? 2 : val == TYPE_VIDEO_9 ? 3 : 1);

            this.pageCamInd = pM;
            this.currentRowInd = rOne;
            this.currentCamInd = cOne;
            this.currentPage = pM;

            this.getCurrentCameraSelected();
        },
        listVideoHandle: function(){
            this.getCurrentCameraSelected();
        },
        listVideoCurrent: function() {
            this.handleSortVideo();
            this.handleCheckIsRecordCamera();
        },
        currentRowInd: function() {
            this.handleMuted()
        },
        currentCamInd: function() {
            this.handleMuted()
        },
        pageCamInd: function() {
            this.handleMuted()
        },
        isMute: function() {
            this.handleMuted()
        },
        isPlay: function() {
            this.handlePlayOrPauseVideo();
        },
        isFullScreen: function() {
            this.resizeVideo();
        },
        isShowPopupChooseCam: function(val) {
            document.getElementsByClassName("tree-box")[0].style.display = val ? "block" : "none";
        },
        currentPage: function(val) {
            if(this.currentTypeColumn == TYPE_VIDEO_1){
                this.pageCamInd = val;
            }
            this.getCurrentCameraSelected();
        },
    }
}
</script>

<style scoped>
.bound-one-camera {
    width: 100%;
    height: 100%;
}
</style>

<style>
#content-live div[id^=glplayer-] {
    width: 100% !important;
    height: 100% !important;
    position: relative;
}

#content-live video[id^=glplayer-] {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
}

#content-live div[id^=glplayer-] canvas {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    margin-top: 0px !important;
    margin-left: 0px !important;
    object-fit: contain;
}
</style>
